(function(document, window, $) {
  'use strict';

  var Site = window.Site;

  $(document).ready(function($) {
    Site.run();

      window.initSelectize = function() {

          $('[data-plugin=selectize]').each(function () {


              var $selectItem = $(this).selectize({
                  plugins: ['drag_drop', 'remove_button'],
                  delimiter: ',',
                  persist: false,
                  create: function (input) {
                      return {
                          value: input,
                          text: input
                      }
                  }
              });

              if ($(this).hasClass('selectize-modal')) {
                  $(document).on('savemodal:clear', function (event) {
                      var selectize = $selectItem[0].selectize;
                      selectize.clear();
                  });
              }

              $('#filter').on('filter:clear', function (event) {
                  var selectize = $selectItem[0].selectize;
                  selectize.clear();
              });

          });
      }

      initSelectize();


      var xhr;
      var select_zone, $select_zone;
      var select_city, $select_city;
      var havezones = false;

      window.initSelectizeCities = function() {

          $('[data-plugin=selectize-cities]').each(function () {

              var url = $(this).attr('data-url');

              var $selectItem = $(this).selectize({
                  valueField: 'id',
                  labelField: 'text',
                  // searchField: 'text',
                  create: false,

                  load: function (query, callback) {

                      if (!query.length) return callback();
                      $.ajax({
                          url: url + '?q=' + encodeURIComponent(query),
                          type: 'GET',
                          error: function () {
                              callback();
                          },
                          success: function (res) {

                              callback(res.items);

                          }
                      });
                  },

                  onChange: function (value) {
                      if (!value.length) return;
                      if (!havezones) return;
                      select_zone.disable();
                      select_zone.clearOptions();
                      select_zone.load(function (callback) {
                          xhr && xhr.abort();
                          xhr = $.ajax({
                              url: $('#select-city-zones').attr('data-url') + '?cityid=' + encodeURIComponent(value),
                              success: function (results) {
                                  select_zone.enable();
                                  callback(results.items);
                              },
                              error: function () {
                                  callback();
                              }
                          })
                      });
                  }

              });

          });
      };

      initSelectizeCities();


    if ($('#select-city-zones').length) {
        havezones = true;
        $select_zone = $('#select-city-zones').selectize({
            valueField: 'id',
            labelField: 'text',
            searchField: ['text'],
            plugins: ['remove_button'],
        });

        select_zone = $select_zone[0].selectize;
    }

      window.initSelectizeAjax = function() {


          $('[data-plugin=selectizeajax]').each(function () {

              var url = $(this).attr('data-url');


              var $selectItem = $(this).selectize({
                  plugins: ['remove_button'],
                  valueField: 'id',
                  labelField: 'name',
                  searchField: 'name',
                  create: false,
                  render: {
                      option: function (item, escape) {
                          var email = '';
                          if (item.emails != '' && item.emails != undefined) {
                              email = '<span class="email"><i class="ti-email"></i> ' + item.emails + '</span>';
                          }

                          var phone = '';
                          if (item.phones != '' && item.phones != undefined) {
                              phone = '<span class="phone"><i class="fa-phone"></i> ' + item.phones + '</span>';
                          }


                          return '<div class="autosuggest-box">' +
                              '<span class="name">' + escape(item.name) + '</span>' +
                              '<span class="autosuggest-box-info">' +
                              email +
                              phone +
                              '</span>' +
                              '</div>';
                      }
                  },


                  load: function (query, callback) {

                      if (!query.length) return callback();
                      $.ajax({
                          url: url + '?q=' + encodeURIComponent(query),
                          type: 'GET',
                          error: function () {
                              callback();
                          },
                          success: function (res) {

                              callback(res.items);

                          }
                      });
                  }
              });

              var listen = $(this).attr('data-listen');

              if (listen != undefined) {
                  $(document).on('selectizeajax:'+listen, function (event, item) {
                      $selectItem[0].selectize.renderCache = {};
                      $selectItem[0].selectize.addOption(item);
                      $selectItem[0].selectize.addItem(item.id);
                  });
              }

          });
      }

      initSelectizeAjax();

  });





})(document, window, jQuery);
